<!--  -->
<template>
    <div class='com-game-color-notice-pro flex-center' v-if="active">
        <div class="c-n-body flex-just-center" :class="{'box-animation': boxActive == 1, 'box-close': boxActive == 2}">
            <div class="body-content">
                <div class="content-bcg"></div>
                <div class="content-text" :class="{'content-animation': contentActive}">
                    <div class="stone" :class="color"></div>
                    <div class="text flex-center">
                        <span class="text-large-b bold">{{text}}</span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            color: String,
        },
        data() {
            return {
                aniBoxTime: 500,
                aniContentTime: 800,
                active: false,
                boxActive: 0,
                contentActive: false
            };
        },
        methods: {
            async open(){
                this.active = true
                this.voicePlay()
                await this._common.settimeout(300)
                this._voiceHand.normalPlay('game start')
                await this.boxAnimationStart()
                await this.contentAnimationStart()
                await this._common.settimeout(500)
                this.close()
                
            },
            async close(){
                await this.boxAnimationClose()
                this.boxActive = 0
                this.contentActive = false
                this.active = false
            },
            stop(){
                if(this.active) this.close()
                this._voiceHand.normalStop('game start')
            },
            async boxAnimationStart(){
                this.boxActive = 1
                await this._common.settimeout(this.aniBoxTime)
                return true
            },
            async boxAnimationClose(){
                this.boxActive = 2
                await this._common.settimeout(this.aniBoxTime)
                return true
            },
            async contentAnimationStart(){
                this.contentActive = true
                await this._common.settimeout(this.aniContentTime)
                return true
            },
            async contentAnimationClose(){

            },
            async voicePlay(){
                let key = this.voiceKey
                this._voiceHand.normalPlay(key)
            }
        },
        created() {

        },
        components: {
        },
        computed: {
            voiceKey(){
                let key = `color_${this.color}`
                return key
            },
            text(){
                let color = this.color == 'black' ? '黑' : '白'
                return `我方执${color}棋`
            },
            
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-game-color-notice-pro{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 302;
        // transition: all .3s;
        .box-animation{
            animation: scale-open .5s forwards;
        }
        .box-close{
            animation: scale-close .5s forwards;
        }
        .content-animation{
            animation: hide-bottom-open .8s ease forwards;
        }
        .c-n-body{
            width: 100%;
            height: 265px;
            padding-top: 71px;
            transform: scale(0);
            .background-img-max(url('@{assetsUrlV2_0}/game/g34.png'));
            // animation: scale-open .5s infinite;
        }
        .body-content{
            width: 472px;
            height: 158px;
            position: relative;
            .content-bcg{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g35.png'));
            }
            .content-text{
                transform: translateY(-20%);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
            }
            .stone{
                width: 32px;
                height: 32px;
                margin: 24px auto 16px;
            }
            .stone.black{
                .background-img-max(url('@{assetsUrlV2_0}/game/g19.png'));
            }
            .stone.white{
                .background-img-max(url('@{assetsUrlV2_0}/game/g18.png'));
            }
        }
    }
</style>