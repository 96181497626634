<template>
    <div class="com-global-countdown timer-de flex">
        <span class="text-mini-a bold">{{text}}</span>
        <slot name="unit"></slot>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            value: {
                type: [Number, String],
                default: 'common'
            },
            type: {
                type: String,
                default: 'common'
            },
            log: String,
            audio: Boolean
        },
        data() {
            return {
                valueData: 0,
                initState:false,
                countdownControl: null,
                t: 50,
                status: false,
            };
        },
        methods: {
            setValue(val){
                if(val === undefined) val = this.value
                this.valueData = val * 1000
            },
            init(){
                this.setValue()
            },
            async start(){
                if(this.valueData <= 0) return this.timeout()
                this.status = true
                this.action()
            },
            async action(){
                this.clear()
                if(this.valueData < 0) return this.timeout()
                let {t} = this
                this.status = true
                this.countdownControl = setInterval(() => {
                    if(this.valueData < 0) return this.timeout()
                    this.valueData -= t
                }, t)
            },
            clear(){
                if(!this.countdownControl) return
                // clearTimeout(this.countdownControl)
                clearInterval(this.countdownControl)
            },
            stop(){
                this.status = false
                this.clear()
            },
            async timeout(){
                this.clear()
                await this._common.nextTick.call(this)
                this.$emit('timeout')
            },
            audioPlay(val, oldVal){
                if(!this.status || val == oldVal) return
                this.$emit('intervalCallback', val)
            }
        },
        created() {},
        components: {},
        computed: {
            text(){
                let {type} = this
                if(this[`${type}Text`] === undefined) return null
                return this[`${type}Text`]
            },
            commonText(){
                let data = Math.ceil(this.valueData / 1000)
                if(data < 0) data = 0
                if(data < 10) data = `0${data}`
                return data
            },
            timeText(){
                let {valueData: value} = this
                value = Math.ceil(value / 1000)
                let m = parseInt(value / 60)
                let s = value % 60
                if(m < 0) m = 0
                if(s < 0) s = 0
                // let h = parseInt(m / 60)
                let data = this._common.serverTimeFormat({m, s})
                // return `${data.h}:${data.m}:${data.s}`
                return `${data.m}:${data.s}`
            },
        },
        watch: {
            valueData: {
                handler(val, oldVal){
                    let data = Math.ceil(val / 1000)
                    let oldData = Math.ceil(oldVal / 1000)
                    this.$emit('change', data)
                    this.audioPlay(data, oldData)
                },
                immediate: true,
                deep: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.stop()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    
</style>