import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'game'
        let name = 'game'
        opt.localKey = localKey
        opt.name = name
        super(opt)
        this.defaultInit()
    }
    defaultInit(){
        let {boardSize, style, org, resign, rule, elodiff, komi, level} = this
        boardSize = this.boardSize === undefined ? 19 : this.boardSize
        style = this.style === undefined ? 555555 : this.style
        org = this.org === undefined ? this.PARAMS.client_id : this.org
        resign = this.resign === undefined ? 2 : this.resign
        rule = this.rule === undefined ? 'chinese' : this.rule
        elodiff = this.elodiff === undefined ? 0 : this.elodiff
        komi = this.komi === undefined ? 7.5 : this.komi
        level = this.level === undefined ? 0 : this.level
        this.init({boardSize, style, org, resign, rule, elodiff, komi, level})
    }
    setParams(items){
        this.init(items)
    }
    getHandicapMoves(num, boardSize){
        boardSize = boardSize || 19
        let game = this._appData.getStoreItem('game')
        if(!game[`handicap${boardSize}`] || !game[`handicap${boardSize}`][num]) return []
        return game[`handicap${boardSize}`][num]
    }

    async getLevelList(boardSize){
        let options = {
            cacheData: true,
            data: {
            },
        }
        let res = await this.requestAction('levelList', options)
        if(res){
            if(boardSize){
                let data = []
                for(let i in res){
                    let size = res[i].boardSize
                    if(size != boardSize) continue
                    data.push(res[i])
                }
                res = data
            }
            for(let i in res){
                let {description} = res[i]
                if(!this._common.isObject(description)){
                    try{
                        description = JSON.parse(description)
                    }catch(e){description = {}}
                }   
                res[i].description = description
            }
        }
        return res
    }

    async getAIList(boardSize){
        let options = {
            cacheData: true,
            data: {
            },
        }
        let res = await this.requestAction('AIList', options)
        if(res && boardSize){
            let data = []
            for(let i in res){
                let size = res[i].boardSize
                if(size != boardSize) continue
                data.push(res[i])
            }
            res = data
        }
        return res
    }

    genmoveParams(options){
        options = options || {}
        let defaultPar = {
            moves: '',
            boardSize: this.boardSize,
            level: this.level,
            style: this.style,
            komi: this.komi,
            elodiff : this.elodiff,
            rule: this.rule,
            resign: this.resign,
            org: this.org
        }
        let postData = {}
        for(var i in defaultPar){
            postData[i] = defaultPar[i]
            if(options[i] !== undefined){
                postData[i] = options[i]
            }
        }
        postData.boardsize = postData.boardSize
        postData.board_size = postData.boardSize
        postData.situation = postData.moves
        return {...options, ...postData}
    }
    probsParams(options){
        let opt = this.genmoveParams(options)
        delete opt['boardSize']
        delete opt['elodiff']
        delete opt['resign']
        opt.level = 8888   
        return opt     
    }
    async genmove(params){
        let {apiName, AITime} = params
        if(!this.apiList[apiName]) apiName = 'genmove'
        params = this.genmoveParams(params)
        if(params.level === undefined) return false
        let options = {
            noLoad: true,
            noError: true,
            data: {
                ...params
            },
        }
        let time = parseInt(new Date() / 1000)
        let res = await this.requestAction(apiName, options)
        try{
            res = JSON.parse(res)
        }catch(e){
            if(!this._common.isObject(res)) res = {}
        }
        await this.genmoveDelay(time, AITime)
        return res
    }
    async genmoveDelay(time, AITime){
        if(!AITime) return this.genmoveDelayCommon(time)
        return this.genmoveDelayParams(time, AITime)
    }

    getDelayTime(time){
        time = time || ''
        let min = null, max = null
        try{
            time = time.split(',')
        }catch(e){ time = [] }
        // if(!time.length) return {min: 0, max: 0}
        min = time[0]
        if(time.length > 1) max = time[1]
        else max = min
        min = Number(min)
        max = Number(max)
        if(!this._common.isNumber(min)) min = 0
        if(!this._common.isNumber(max)) max = 0
        return {min, max}
    }
    async genmoveDelayParams(time, AITime){
        let t = this.getDelayTime(AITime)
        let {min, max} = t
        let num = this._common.randomNumber(min, max)
        return this.genmoveDelayAction(time, num)
    }

    async genmoveDelayCommon(time){
        let start = this.PARAMS.genmoveDelayStart, end = this.PARAMS.genmoveDelayEnd
        let num = this._common.randomNumber(start, end)
        return this.genmoveDelayAction(time, num)
    }
    async genmoveDelayAction(time, num){
        let time_now = parseInt(new Date() / 1000)
        let delay = (num - (time_now - time)) * 1000
        await this._common.settimeout(delay)
        return true
    }
    // /dcnn/group/judge

    async saveGame(opt){
        opt = opt || {}
        let options = {
            ...opt,
            method: 'post',
            data: opt,
        }
        let res = await this.requestAction('saveGame', options)
        if(res){
            let gift = await this.gameGift()
            res = {
                gameId: res,
            }
            if(gift){
                res.gift = gift
            }
        }
        return res
    }

    async gameGift(opt){
        opt = opt || {}
        let {usercode} = opt
        usercode = usercode || this._appData.getItem('usercode', 'app')
        // school_id = school_id || this.PARAMS.school_id
        opt = {
            ...opt,
            usercode
        }
        let options = {
            ...opt,
            fullData: true,
            method: 'get',
            data: {
                // school_id
            },
        }
        let res = await this.requestAction('gameGift', options)
        if(res && res.code == 0) this.balanceCheck()
        res = res || {}
        return res.data
    }

    async patternList(){
        let options = {
        }
        let res = await this.requestAction('patternList', options)
        if(res){
            for(let i in res){
                let {resourceDetail} = res[i]
                try{
                    resourceDetail = JSON.parse(resourceDetail)
                }catch(e){
                    resourceDetail = {}
                }
                res[i].resourceDetail = resourceDetail
            }
        }
        return res
    }
    
    async judge(params){
        params = this.probsParams(params)
        if(!params.level) return false
        let options = {
            ...params,
            data: {
                ...params
            },
        }
        let res = await this.requestAction('judge', options)
        return res
    }

    // 创建房间
    async createRoom(params){
        let options = {
            ...params,
            data: {
                ...params
            },
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }
        let res = await this.requestAction('createRoom', options)
        return res
    }

    async rankList(opt){
        let options = {
            ...opt,
            data: {
                ...opt
            },
            method: 'get',
        }
        let res = await this.requestAction('rankList', options)
        return res
    }
}

export default singleProxy(game)
// export default game