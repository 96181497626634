<!--  -->
<template>
    <div class='com-options-mask'>
        <div class="com-options-mask-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {
                optLeft: 0,
                appStyle: {}
            };
        },
        methods: {
            eventSet(){
                this.eventOff()
                this._common.eventSet('onresize', e => {
                    this.sizeChange()
                })
            },
            eventOff(){
                this._common.eventRemove('onresize')
            },
            sizeChange(){
                let optEle = document.querySelector('.page-game-capture .capture-body .cap-item.options')
                let {left, top} = this._common.getVertexPosition(optEle)
                this.optLeft = left - 1
                let {appStyle = {}} = this.$store.state
                this.appStyle = appStyle
            },
        },
        created() {
            this.eventSet()
        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {
            this.sizeChange()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-options-mask{
        position: absolute;
        top: 3px;
        left: -1px;
        width: 267px;
        height: 100%;
        z-index: 2;
        .background-img-max(url('@{assetsUrlV2_0}/exercise/c15.png'));
        .com-options-mask-body{
            width: @game-options-size;
            height: 100%;
        }
    }
</style>