<template>
    <div class="com-global-game-time flex">
        <div class="game-time-item">
            <countdown ref="mainTime" :value="mainTimeCul" type="time" @change="change('mainTime', $event)" @timeout="timeout('mainTime', $event)" log="mainTime"></countdown>
        </div>
        <div class="game-time-item">
            <countdown ref="countdownTime" :value="countdownCul" @change="change('countdownTime', $event)" @timeout="timeout('countdownTime', $event)" @intervalCallback="intervalCallback">
                <span class="text-mini-a bold" slot="unit">秒</span>
            </countdown>
        </div>
        <div class="game-time-item timer-de flex">
            <span class="text-mini-a bold timer-de">{{countdownNumVal | countdownNumFilter}}次</span>
        </div>
    </div>
</template>
<script>
    import countdown from './countdown.vue'

    export default {
        name: '',
        filters: {
            countdownNumFilter(val){
                if(val > 0) return val
                return 1
            }
        },
        props: {
            mainTime: {
                type: Number,
                default: 0
            },
            countdownTime: {
                type: Number,
                default: 0
            },
            countdownNum: {
                type: Number,
                default: 0
            },
            type: {
                type: String,
                default: 's'
            },
            timeoutStepCallback: Function,
            color: String
        },
        data() {
            return {
                mainTimeVal: null,
                countdownTimeVal: null,
                countdownNumVal: null,
                timeState: false,
                countdownTimeoutObj: null
            };
        },
        methods: {
            start(type){
                let {mainTimeVal, countdownTimeVal, countdownNumVal} = this
                let f = false
                if(mainTimeVal > 0) this.mainTimeAction(), f = true
                else if(countdownNumVal > 0 || countdownTimeVal > 0){
                    if(type != 'continue') this.$refs.countdownTime.setValue(this.countdownCul)
                    this.countdownAction()
                    f = true
                }
                if(!f) return
                this.timeState = true
                
            },
            continue(){
                this.startAction()
            },
            change(key, val){
                key = `${key}Val`
                if(this[key] === undefined) return
                this[key] = val
            },
            mainTimeAction(){
                this.$refs.mainTime.start()
            },
            async countdownAction(){
                this.$refs.countdownTime.start()
            },
            reset(){
                if(this.$refs.mainTime) this.$refs.mainTime.init()
                if(this.$refs.countdownTime) this.$refs.countdownTime.init()
                // if(this.$refs.countdownNum) this.$refs.countdownNum.init()
                this.countdownNumVal = this.countdownNum
                
            },
            stop(){
                if(this.$refs.mainTime) this.$refs.mainTime.stop()
                if(this.$refs.countdownTime) this.$refs.countdownTime.stop()
                // if(this.$refs.countdownNum) this.$refs.countdownNum.stop()
                this.timeState = false
            },
            timePause(){
                this.stop()
            },
            setValue(data){
                for(let i in data){
                    if(!this.$refs[i]) continue
                    this.$refs[i].setValue(data[i])
                }
                if(data.countdownNum !== undefined) this.countdownNumVal = data.countdownNum
            },
            timeout(type){
                let key = `${type}Timeout`
                if(!this[key]) return
                this[key]()
            },
            async mainTimeTimeout(){
                if(this.countdownTimeVal <= 0 && this.countdownNumVal <= 0) return this.timeoutEmit()
                if(this.timeoutStepCallback){
                    let state = await this.timeoutStepCallback({type: 'mainTime'})
                    if(!state) return
                }
                this.start()
            },
            async countdownTimeTimeout(){
                await this._common.settimeout(1000)
                if(!this.timeState) return
                if(this.countdownTimeVal > 0) return
                this.countdownNumVal--
                if(this.countdownNumVal <= 0) return this.timeoutEmit()
                this.$refs.countdownTime.setValue(this.countdownTimeCul)
                if(this.timeoutStepCallback){
                    console.log(this.countdownNumVal)
                    let state = await this.timeoutStepCallback({type: 'countdownTime', countdownNum: this.countdownNumVal})
                    if(!state) return
                }
                return this.start()
            },
            async countdownReset(){
                this.$refs.countdownTime.setValue(this.countdownTimeCul)
                await this._common.settimeout(1000)
            },
            timeoutEmit(){
                this.$emit('timeout')
            },
            timeValueChangeEmit(val){
                if(this.isMsTime){
                    val.mainTime = val.mainTime * 1000
                    val.countdownTime = val.countdownTime * 1000
                }
                // if(
                //     val.mainTime === undefined || val.mainTime === null ||
                //     val.countdownTime === undefined || val.countdownTime === null || 
                //     val.countdownNum === undefined || val.countdownNum === null
                // ) return
                this.$emit('change', val)
            },
            timeUpdateByGameState(data){
                data = data || {}
                let eles = {
                    mainTime: this.timeParse(data.mainTime),
                    countdownTime: this.timeParse(data.countdownTime),
                    countdownNum: data.countdownNum
                }
                let state = this.timeState
                this.stop()
                this.setValue(eles)
                // this.countdownNumVal = data.countdownNum
                if(state) this.start()
            },
            timeParse(data){
                if(this.type == 'ms') data = data / 1000
                return data
            },
            async intervalCallback(value){
                await this._common.nextTick.call(this)
                if(this.timeoutStepCallback) this.timeoutStepCallback({type: 'step', value})
            },
            timeContinue(){
                this.start('continue')
            },
        },
        created() {},
        components: {
            countdown
        },
        computed: {
            isMsTime(){
                return this.type == 'ms'
            },
            mainTimeCul(){
                let {mainTime} = this
                let data = this.timeParse(mainTime)
                return data
            },
            countdownCul(){
                let {countdownTime} = this
                let data = this.timeParse(countdownTime)
                return data
            },
            timeValueChange(){
                let {mainTimeVal: mainTime, countdownTimeVal: countdownTime, countdownNumVal: countdownNum} = this
                return {mainTime, countdownTime, countdownNum}
            },
        },
        watch: {
            countdownNum: {
                handler(val, oldVal){
                    if(!Number(val) || val == oldVal) return
                    this.countdownNumVal = val
                },
                immediate: true,
                deep: true
            },
            timeValueChange: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal)) return
                    this.timeValueChangeEmit(val)
                },
                immediate: true,
                deep: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-global-game-time{
        width: 100%;
        justify-content: center;
        .game-time-item+ .game-time-item{
            margin-left: 4px;
        }
    }
</style>